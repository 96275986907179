import PropTypes from "prop-types";
import React from "react";
import { View, StyleSheet, Image, Text } from "@react-pdf/renderer";
import { addPlusToPhone, adjust } from "../../../utils";

const styles = StyleSheet.create({
  logo: {
    width: adjust(558.04),
    height: adjust(80),
    objectFit: "contain",
  },
  logoText: {
    fontSize: adjust(40),
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: adjust(40),
    paddingHorizontal: adjust(75),
    borderBottom: adjust(4),
    borderColor: "#BA9753",
  },
  userInfoRow: {
    alignItems: "center",
    flexDirection: "row",
  },
  userInfoRowLabel: {
    fontSize: adjust(24),
    marginRight: adjust(30),
    minWidth: adjust(90),
  },
  userInfoRowValue: {
    fontSize: adjust(32),
    fontWeight: 500,
  },
});

export default function Header({ company, user }) {
  const Logo = React.useMemo(() => {
    if (company.company_logo[0]) {
      return (
        <Image src={company.company_logo[0].file_url} style={styles.logo} />
      );
    }

    return <Text style={styles.logoText}>{company.name}</Text>;
  }, [company]);

  return (
    <>
      <View
        style={styles.header}
        fixed
      >
        {Logo}
        {!company.is_managed_by_getheli && (
          <View>
            {user.data.full_name && (
              <View style={styles.userInfoRow}>
                <Text style={styles.userInfoRowLabel}>Contact</Text>
                <Text style={styles.userInfoRowValue}>
                  {user.data.full_name}
                </Text>
              </View>
            )}
            {user.data.phone && (
              <View style={styles.userInfoRow}>
                <Text style={styles.userInfoRowLabel}>Phone</Text>
                <Text style={styles.userInfoRowValue}>
                  {addPlusToPhone(user.data.phone)}
                </Text>
              </View>
            )}
            {user.data.email && (
              <View style={styles.userInfoRow}>
                <Text style={styles.userInfoRowLabel}>Email</Text>
                <Text style={styles.userInfoRowValue}>{user.data.email}</Text>
              </View>
            )}
          </View>
        )}
      </View>
    </>
  );
}

Header.propTypes = {
  company: PropTypes.object,
  user: PropTypes.object,
};
