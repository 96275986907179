import React from "react";
import PropTypes from "prop-types";
import { Image, Text, View } from "@react-pdf/renderer";
import { adjust } from "../../../utils";
import warning from "../../../assets/icons/warning-yellow.png";

const Disclaimer = ({ firstLineText, secondLineText }) => {
  return (
    <View
      style={{
        paddingHorizontal: adjust(30),
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginTop: adjust(30),
      }}
    >
      <Image
        src={warning}
        style={{
          width: adjust(28),
          height: adjust(28),
          marginRight: adjust(12),
        }}
      />
      <View
        style={{
          marginRight: adjust(24),
          fontSize: adjust(24),
        }}
      >
        <Text>{firstLineText}</Text>
        <Text>{secondLineText}</Text>
      </View>
    </View>
  );
};
Disclaimer.propTypes = {
  firstLineText: PropTypes.string,
  secondLineText: PropTypes.string,
};
export default Disclaimer;
