import { StyleSheet, View, Text, Link } from "@react-pdf/renderer";
import { adjust } from "../../../utils";

const styles = StyleSheet.create({
  terms: {
    marginHorizontal: adjust(60),
    borderWidth: adjust(4),
    borderColor: "#BA9753",
    borderRadius: adjust(4),
    padding: adjust(50),
    color: "#242F42",
    marginTop: adjust(60),
  },
  title: {
    fontSize: adjust(24),
    textTransform: "uppercase",
    fontWeight: 500,
  },
  subtitle: {
    fontSize: adjust(22),
    color: "#6d7481",
    marginTop: adjust(6),
  },
  titleBorder: {
    paddingBottom: adjust(20),
    borderBottom: adjust(1),
    borderColor: "#BA9753",
    marginBottom: adjust(20),
  },
  label: {
    fontWeight: 500,
    fontSize: adjust(24),
    textDecoration: "none",
    color: "#242F42",
  },
  value: {
    fontSize: adjust(24),
    marginBottom: adjust(4),
  },
  sections: {
    flexDirection: "row",
  },
  feeLine: {
    paddingVertical: adjust(20),
    borderBottom: adjust(1),
    borderColor: "#BA9753",
    color: "#6D7481",
    fontSize: adjust(24),
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

export default function ServicesTerms() {
  return (
    <View
      style={styles.terms}
      wrap={false}
    >
      <View style={styles.sections}>
        <View style={{ width: "50%" }}>
          <Text
            style={{
              ...styles.title,
              ...styles.titleBorder,
              marginTop: adjust(10),
            }}
          >
            key commercial terms
          </Text>
          <Text
            style={styles.value}
            break
          >
            <Link style={styles.label}>Landing permission:</Link> The landing site is subject to permission and landing
            slot availability. Any sites selected when submitting a request are subject to operator confirmation.
          </Text>
          <Text
            style={styles.value}
            break
          >
            <Link style={styles.label}>Pilot/Crew Availability:</Link> Subject to continued aircraft and pilot
            availability.
          </Text>
          <Text
            style={styles.value}
            break
          >
            <Link style={styles.label}>Operating hours:</Link> Subject to the confirmation of the operating hours of the
            landing site and standard airfield hours.
          </Text>
          <Text
            style={styles.value}
            break
          >
            <Link style={styles.label}>Pax. & Luggage weight/numbers:</Link> Subject to the confirmation of the exact
            passenger weights, luggage along with any other relevant information.
          </Text>
          <Text
            style={styles.value}
            break
          >
            <Link style={styles.label}>Confirmation at time of booking:</Link> Based on the prices at the time of
            booking. Subject to the change of aviation fuel prices, landing fees and other costs.
          </Text>
          <Text
            style={styles.value}
            break
          >
            <Link style={styles.label}>Weather conditions:</Link> Subject to suitable weather conditions. Final weather
            determination will be made on the day of the flight.
          </Text>
          <Text
            style={{ ...styles.value, marginBottom: 0 }}
            break
          >
            <Link style={styles.label}>Post Confirmation Amendments:</Link> Requests for post-confirmation amendments by
            the broker are only permitted to the extent they are accepted, and the operator reserves the right to charge
            such additional amounts.
          </Text>
          {/*<Text style={{ ...styles.subtitle, marginTop: adjust(20) }}>*/}
          {/*  * Any amendment to this terms should be agreed on with your counterpart*/}
          {/*</Text>*/}
        </View>
        <View style={{ width: "50%", marginLeft: adjust(192) }}>
          <Text style={{ ...styles.title, ...styles.titleBorder, marginTop: adjust(10) }}>cancellation fees</Text>
          <View style={{ flexDirection: "row" }}>
            <Text
              style={{ ...styles.label, marginRight: adjust(20), width: "50%" }}
              break
            >
              Amount of notice of cancellation prior to commencement of the Programme
            </Text>
            <Text
              style={{ ...styles.label, width: "50%" }}
              break
            >
              Cancellation charge - as a percentage of the Flight Fee (excluding additional expenses)
            </Text>
          </View>
          <Text style={{ ...styles.titleBorder, marginBottom: 0 }} />
          <View style={styles.feeLine}>
            <Text style={{ maxWidth: adjust(438), marginRight: adjust(34) }}>Over 7 days</Text>
            <Text>10%</Text>
          </View>
          <View style={styles.feeLine}>
            <Text style={{ maxWidth: adjust(438), marginRight: adjust(34) }}>7 days to 72 hours</Text>
            <Text>25%</Text>
          </View>
          <View style={styles.feeLine}>
            <Text style={{ maxWidth: adjust(438), marginRight: adjust(34) }}>Less than 72 hours</Text>
            <Text>50%</Text>
          </View>
          <View style={styles.feeLine}>
            <Text style={{ maxWidth: adjust(438), marginRight: adjust(34) }}>Less than 48 hours</Text>
            <Text>75%</Text>
          </View>
          <View style={styles.feeLine}>
            <Text style={{ maxWidth: adjust(438), marginRight: adjust(34) }}>
              Less than 12 hours, or cancellation after the Programme has commenced
            </Text>
            <Text>100%</Text>
          </View>
        </View>
      </View>
    </View>
  );
}
