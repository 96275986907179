import PropTypes from "prop-types";
import { StyleSheet, View, Text } from "@react-pdf/renderer";
import { adjust } from "../../../utils";

const styles = StyleSheet.create({
  comments: {
    marginHorizontal: adjust(60),
    borderWidth: adjust(4),
    borderColor: "#BA9753",
    borderRadius: adjust(4),
    padding: adjust(50),
    marginTop: adjust(60),
  },
  commentsTitle: {
    color: "#BA9753",
    fontSize: adjust(36),
    fontWeight: 500,
    textTransform: "uppercase",
    marginBottom: adjust(20),
  },
  commentText: {
    fontSize: adjust(24),
    color: "#242F42",
    fontWeight: 500,
  },
});

export default function Comments({ comment }) {
  return (
    <View
      style={styles.comments}
      wrap={false}
    >
      <Text style={styles.commentsTitle}>Comments</Text>
      <Text style={styles.commentText}>{comment}</Text>
    </View>
  );
}

Comments.propTypes = {
  comment: PropTypes.string,
};
