import React from "react";
import { BlobProvider, PDFViewer } from "@react-pdf/renderer";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { QuoteDetails } from "./QuoteDetails";
import { axiosInstance, fetcher } from "../../services";
import { ReactComponent as Spinner } from "../../assets/icons/spinner.svg";
import { InformationCircleIcon } from "@heroicons/react/solid";

export function QuoteDetailsDebug() {
  const [reqParams, setReqParams] = React.useState({});
  const { data, error, isLoading } = useQuery(
    ["get-pdf-data", reqParams],
    fetcher((params) => {
      axiosInstance.defaults.headers = {
        authorization: `Bearer ${reqParams.access_token}`,
      };

      return axiosInstance.get("/get-pdf-data", { params: { ...params } });
    }),
    { enabled: !!reqParams?.entity_id },
  );
  const downloadLinkStatus = React.useRef(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const result = {};

    for (const [key, value] of params) {
      result[key] = value;
    }

    if (result.entity_id) {
      setReqParams(result);
    } else {
      navigate("/404", { replace: true });
    }
  }, [navigate]);

  const Document = React.useMemo(() => {
    if (data?.model) {
      return <QuoteDetails pdfData={data} />;
    }

    return null;
  }, [data]);

  if (isLoading) {
    return (
      <div className="flex items-center">
        <h3 className="m-0 text-xl font-medium">Loading document data...</h3>
        <Spinner className="ml-3 h-5 w-5 animate-spin text-indigo-600" />
      </div>
    );
  }

  if (error?.response) {
    return (
      <div
        id="alert-additional-content-2"
        className="mb-4 w-full max-w-4xl rounded-lg bg-red-100 p-4 shadow dark:bg-red-200"
        role="alert"
      >
        <div className="flex items-center">
          <InformationCircleIcon className="mr-2 h-5 w-5 text-red-700 dark:text-red-800" />
          <h3 className="text-lg font-medium text-red-700 dark:text-red-800">Something went wrong!</h3>
        </div>
        <div className="mt-2 mb-4 text-sm text-red-700 dark:text-red-800">{error.response?.data.error}</div>
        <div className="flex">
          <a
            href="https://getheli.com/"
            rel="noreferrer noopener"
          >
            <button
              type="button"
              className="mr-2 inline-flex items-center rounded-lg bg-red-700 px-3 py-1.5 text-center text-xs font-medium text-white hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 dark:bg-red-800 dark:hover:bg-red-900"
            >
              Back to Home Page
            </button>
          </a>
        </div>
      </div>
    );
  }

  if (!data?.model) return null;

  return (
    <>
      <PDFViewer
        width="100%"
        height={window.innerHeight}
      >
        {Document}
      </PDFViewer>
      <BlobProvider document={Document}>
        {({ url, loading, error }) => {
          if (url && !loading && !error) {
            const downloadLink = document.createElement("a");

            downloadLink.download = `Quote Details ${data?.model?.id || ""}.pdf`;
            downloadLink.href = url;

            document.body.appendChild(downloadLink);

            if (!downloadLinkStatus.current) {
              downloadLink.click();
              downloadLinkStatus.current = true;
            }
          }

          if (loading) {
            return (
              <div className="fixed top-1/2 left-1/2 flex -translate-x-1/2 -translate-y-1/2 transform items-center">
                <h3 className="m-0 text-xl font-medium">Preparing data...</h3>
                <Spinner className="ml-3 h-5 w-5 animate-spin text-indigo-600" />
              </div>
            );
          }

          return null;
        }}
      </BlobProvider>
    </>
  );
}
