import PropTypes from "prop-types";
import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { adjust, getLegLocationAdditionalInfo, renderTime } from "../../../../utils";
import dayjs from "dayjs";
import { blockLineAlign, blockVisibility, cutLegLocations } from "./Leg.utils";

const styles = StyleSheet.create({
  leg: {
    borderWidth: adjust(4),
    borderColor: "#BA9753",
    padding: adjust(50),
    paddingBottom: adjust(35),
    color: "#242F42",
    borderRadius: adjust(4),
    marginTop: adjust(55),
    position: "relative",
  },
  legContent: {
    flexDirection: "row",
    alignItems: "flex-start",
  },
  legPrefix: {
    flexDirection: "row",
    color: "#BA9753",
    fontSize: adjust(36),
    fontWeight: 500,
    textTransform: "uppercase",
    alignItems: "center",
    marginRight: adjust(84.5),
  },
  label: {
    fontSize: adjust(24),
  },
  title: {
    fontSize: adjust(36),
    marginRight: adjust(20),
  },
  blocksRow: {
    flexDirection: "row",
  },
  block: {
    width: "50%",
  },
  blockLine: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: adjust(15),
  },
  separator: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: adjust(100),
    width: "100%",
  },
  separatorLine: {
    width: "100%",
    height: adjust(2),
    backgroundColor: "#BA9753",
  },
  warning: {
    fontSize: adjust(22),
    color: "#6D7481",
  },
  additionalInfo: {
    fontSize: adjust(24),
    color: "#6D7481",
  },
});

export default function Leg({ index, leg }) {
  return (
    <View
      style={{ ...styles.leg, marginTop: index === 1 ? adjust(35) : adjust(55) }}
      wrap={false}
    >
      <View style={styles.legContent}>
        <View style={styles.legPrefix}>
          <Text>Leg {index}</Text>
        </View>
        <View>
          <View style={styles.blocksRow}>
            <View style={styles.block}>
              {leg.aircrafts.map((aircraft, index) => (
                <View
                  key={aircraft.id}
                  style={styles.blockLine}
                >
                  <Text style={{ ...styles.label, marginRight: adjust(42), minWidth: adjust(228) }}>
                    {index === 0 ? "Helicopter(s) & Crew" : ""}
                  </Text>
                  <Text style={styles.title}>{aircraft.aircraft_model}</Text>
                  <Text style={styles.title}>with</Text>
                  <Text style={styles.title}>
                    {aircraft.crew_size} {`pilot${aircraft.crew_size > 1 ? "s" : ""}`}
                  </Text>
                  {index < leg.aircrafts.length - 1 ? <Text style={styles.label}>and</Text> : null}
                </View>
              ))}
            </View>
            <View style={styles.block}>
              <View style={styles.blockLine}>
                <Text style={{ ...styles.label, marginRight: adjust(40), minWidth: adjust(340) }}>
                  Date of Departure
                </Text>
                <Text style={styles.title}>{dayjs(leg.date).format("DD MMMM, YYYY")}</Text>
              </View>
              <View style={styles.blockLine}>
                <Text style={{ ...styles.label, marginRight: adjust(40), minWidth: adjust(340) }}>
                  Time of Departure
                </Text>
                <Text style={styles.title}>
                  {dayjs(leg.departure_time).format("HH:mm")} {`(UTC${dayjs().format("Z")})`}
                </Text>
              </View>
              <View style={styles.blockLine}>
                <Text style={{ ...styles.label, marginRight: adjust(40), minWidth: adjust(340) }}>
                  Estimated Total Flight Duration
                </Text>
                <Text style={styles.title}>
                  {renderTime(leg.time_max)} {leg.is_refueling ? " + Refuelling stop(s)" : ""}
                </Text>
              </View>
              {/* <View style={{ ...styles.blockLine, marginBottom: 0 }}>
                <Text style={{ ...styles.label, marginRight: adjust(46), minWidth: adjust(300) }}>
                  Estimated Distance
                </Text>
                <Text style={styles.title}>{leg.estimated_distance} Nautical Miles</Text>
              </View> */}
            </View>
          </View>

          <View style={styles.separator}>
            <View style={styles.separatorLine} />
          </View>

          <View style={styles.blocksRow}>
            <View style={styles.block}>
              <View style={{ ...styles.blockLine, alignItems: "flex-start" }}>
                <Text
                  style={{
                    ...styles.label,
                    marginRight: adjust(42),
                    minWidth: adjust(228),
                    paddingTop: adjust(5),
                  }}
                >
                  Take-off Site
                </Text>
                <View>
                  <Text style={styles.title}>{cutLegLocations(leg.from)}</Text>
                  <Text style={styles.additionalInfo}>{getLegLocationAdditionalInfo(leg.from_location)}</Text>
                </View>
              </View>
              <View style={{ ...styles.blockLine, alignItems: "flex-start" }}>
                <Text
                  style={{
                    ...styles.label,
                    marginRight: adjust(42),
                    minWidth: adjust(228),
                    paddingTop: adjust(5),
                  }}
                >
                  Landing Site
                </Text>
                <View>
                  <Text style={styles.title}>{cutLegLocations(leg.to)}</Text>
                  <Text style={styles.additionalInfo}>{getLegLocationAdditionalInfo(leg.to_location)}</Text>
                </View>
              </View>
            </View>
            <View style={styles.block}>
              {blockVisibility(leg.passengers) ? (
                <View style={styles.blockLine}>
                  <Text style={{ ...styles.label, marginRight: adjust(20), minWidth: adjust(89) }}>Payload</Text>
                  <Text
                    style={{
                      ...styles.label,
                      marginRight: adjust(25),
                      minWidth: adjust(252),
                      fontWeight: 500,
                    }}
                  >
                    Passengers
                  </Text>
                  {leg.passengers.men ? (
                    <Text style={styles.title}>{`${leg.passengers.men} ${
                      leg.passengers.men > 1 ? "Men" : "Man"
                    }`}</Text>
                  ) : null}
                  {leg.passengers.women ? (
                    <Text style={styles.title}>{`${leg.passengers.women} ${
                      leg.passengers.women > 1 ? "Women" : "Woman"
                    }`}</Text>
                  ) : null}
                  {leg.passengers.children ? (
                    <Text style={styles.title}>{`${leg.passengers.children} ${
                      leg.passengers.children > 1 ? "Children" : "Child"
                    }`}</Text>
                  ) : null}
                </View>
              ) : null}

              {blockVisibility(leg.luggage) ? (
                <View style={styles.blockLine}>
                  <Text style={{ ...styles.label, marginRight: adjust(20), minWidth: adjust(89) }} />
                  <Text
                    style={{
                      ...styles.label,
                      marginRight: adjust(25),
                      minWidth: adjust(252),
                      fontWeight: 500,
                    }}
                  >
                    Luggage
                  </Text>
                  {leg.luggage.carry_on_bags ? (
                    <Text style={styles.title}>{leg.luggage.carry_on_bags} Carry-on baggage</Text>
                  ) : null}
                  {leg.luggage.hand_bags ? (
                    <Text style={styles.title}>{leg.luggage.hand_bags} Hold baggage</Text>
                  ) : null}
                </View>
              ) : null}

              {blockVisibility(leg.special_items, { exclude: ["other"] }) ? (
                <View style={{ ...styles.blockLine, alignItems: blockLineAlign(leg.special_items) }}>
                  <Text style={{ ...styles.label, marginRight: adjust(20), minWidth: adjust(89) }} />
                  <Text
                    style={{
                      ...styles.label,
                      marginRight: adjust(25),
                      minWidth: adjust(252),
                      fontWeight: 500,
                    }}
                  >
                    Special Items
                  </Text>
                  <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                    {leg.special_items.ski_pairs ? (
                      <Text style={styles.title}>{leg.special_items.ski_pairs} Ski Pairs</Text>
                    ) : null}
                    {leg.special_items.pets ? <Text style={styles.title}>{leg.special_items.pets} Pets</Text> : null}
                    {leg.special_items.hunting_weapons ? (
                      <Text style={styles.title}>{leg.special_items.hunting_weapons} Hunting Weapons</Text>
                    ) : null}
                    {leg.special_items.golf_club_sets ? (
                      <Text style={styles.title}>{leg.special_items.golf_club_sets} Golf Club Sets</Text>
                    ) : null}
                    {leg.special_items.bicycles ? (
                      <Text style={styles.title}>{leg.special_items.bicycles} Bicycles</Text>
                    ) : null}
                  </View>
                </View>
              ) : null}

              {leg.special_items.other ? (
                <View style={styles.blockLine}>
                  <Text style={{ ...styles.label, marginRight: adjust(20), minWidth: adjust(89) }} />
                  <Text
                    style={{
                      ...styles.label,
                      marginRight: adjust(25),
                      minWidth: adjust(252),
                      fontWeight: 500,
                    }}
                  />
                  <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                    <Text
                      style={{ ...styles.title, maxWidth: adjust(700) }}
                      wrap={true}
                    >
                      {leg.special_items.other}
                    </Text>
                  </View>
                </View>
              ) : null}
              {leg.maximum_weight_allowed && leg.maximum_weight_allowed !== "0.00" ? (
                <View
                  style={{
                    ...styles.blockLine,
                    marginBottom: 0,
                    position: "absolute",
                    bottom: adjust(-25),
                  }}
                >
                  <Text style={{ ...styles.label, marginRight: adjust(20), minWidth: adjust(89) }} />
                  <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                    <Text style={{ ...styles.warning }}>
                      The maximum weight feasible for this specific leg is {leg.maximum_weight_allowed} KG
                    </Text>
                  </View>
                </View>
              ) : null}
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}

Leg.propTypes = {
  index: PropTypes.number,
  leg: PropTypes.object,
};
