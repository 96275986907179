import axios from "axios";
import { getCookie } from "../../utils";

const token = getCookie("access_token") || "";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.defaults.headers = {
  authorization: `Bearer ${token}`,
};

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error),
);

export function fetcher(callback) {
  return async (args) => {
    const { queryKey } = args;

    try {
      const response = await callback(queryKey[1] || {});
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export { axiosInstance };
