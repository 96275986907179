import PropTypes from "prop-types";
import { StyleSheet, View, Text } from "@react-pdf/renderer";
import { adjust } from "../../../../utils";
import Helicopter from "./Helicopter";

const styles = StyleSheet.create({
  list: {
    marginHorizontal: adjust(60),
    marginTop: adjust(55),
    position: "relative",
    borderWidth: adjust(4),
    borderColor: "#BA9753",
    borderRadius: adjust(4),
    padding: adjust(55),
    paddingTop: adjust(25),
  },
  helicoptersPrefix: {
    flexDirection: "row",
    color: "#BA9753",
    fontSize: adjust(36),
    fontWeight: 500,
    textTransform: "uppercase",
    alignItems: "center",
    position: "absolute",
    top: adjust(40),
    left: adjust(50),
  },
});

export default function HelicoptersList({ aircrafts }) {
  return (
    <View style={styles.list}>
      <Text style={styles.helicoptersPrefix}>Helicopters</Text>
      {aircrafts.map((aircraft, index) => (
        <Helicopter
          key={aircraft.id}
          aircraft={aircraft}
          index={index}
        />
      ))}
    </View>
  );
}

HelicoptersList.propTypes = {
  aircrafts: PropTypes.array,
};
