import React from "react";
import { InformationCircleIcon } from "@heroicons/react/solid";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { BlobProvider } from "@react-pdf/renderer";
import { QuoteDetails } from "./QuoteDetails";
import { axiosInstance, fetcher } from "../../services";
import { ReactComponent as Spinner } from "../../assets/icons/spinner.svg";

export function QuoteDetailsAutoDownload() {
  const [reqParams, setReqParams] = React.useState({});
  const downloadLinkStatus = React.useRef(false);
  const { data, error, isLoading } = useQuery(
    ["get-pdf-data", reqParams],
    fetcher((params) => {
      axiosInstance.defaults.headers = {
        authorization: `Bearer ${reqParams.access_token}`,
      };
      return axiosInstance.get("/get-pdf-data", { params: { ...params } });
    }),
    { enabled: !!reqParams?.entity_id },
  );
  const navigate = useNavigate();

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const result = {};

    for (const [key, value] of params) {
      result[key] = value;
    }

    if (result.entity_id) {
      setReqParams(result);
    } else {
      navigate("/404", { replace: true });
    }
  }, [navigate]);

  if (error?.response) {
    return (
      <div
        id="alert-additional-content-2"
        className="mb-4 w-full max-w-4xl rounded-lg bg-red-100 p-4 shadow dark:bg-red-200"
        role="alert"
      >
        <div className="flex items-center">
          <InformationCircleIcon className="mr-2 h-5 w-5 text-red-700 dark:text-red-800" />
          <h3 className="text-lg font-medium text-red-700 dark:text-red-800">Something went wrong!</h3>
        </div>
        <div className="mt-2 mb-4 text-sm text-red-700 dark:text-red-800">{error.response?.data.error}</div>
        <div className="flex">
          <a
            href="https://getheli.com/"
            rel="noreferrer noopener"
          >
            <button
              type="button"
              className="mr-2 inline-flex items-center rounded-lg bg-red-700 px-3 py-1.5 text-center text-xs font-medium text-white hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 dark:bg-red-800 dark:hover:bg-red-900"
            >
              Back to Home Page
            </button>
          </a>
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="flex items-center">
        <h3 className="m-0 text-xl font-medium">Loading document data...</h3>
        <Spinner className="ml-3 h-5 w-5 animate-spin text-indigo-600" />
      </div>
    );
  }

  if (!data?.model) return null;

  return (
    <BlobProvider document={<QuoteDetails pdfData={data} />}>
      {({ url, loading, error }) => {
        if (url && !loading && !error) {
          const downloadLink = document.createElement("a");

          downloadLink.download = `Quote Details ${data?.model?.id || ""}.pdf`;
          downloadLink.href = url;

          document.body.appendChild(downloadLink);

          if (!downloadLinkStatus.current) {
            downloadLink.click();
            downloadLinkStatus.current = true;
          }
        }

        if (loading) {
          return (
            <div className="flex items-center">
              <h3 className="m-0 text-xl font-medium">Preparing data...</h3>
              <Spinner className="ml-3 h-5 w-5 animate-spin text-indigo-600" />
            </div>
          );
        }

        if (error) {
          return (
            <div className="flex items-center">
              <h3 className="m-0 text-xl font-medium">Something went wrong. Failed to generate document.</h3>
            </div>
          );
        }

        return (
          <>
            <div className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="mr-1 h-6 w-6 text-green-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <h3 className="m-0 text-xl font-medium">Document generated successfully</h3>
            </div>
            <div className="absolute bottom-10 left-1/2 -translate-x-1/2 transform text-gray-700">
              Click{" "}
              <a
                download={`Quote Details ${data?.model?.id || ""}.pdf`}
                className="text-indigo-600 underline underline-offset-1"
                href={url}
              >
                here
              </a>{" "}
              if automatic download failed.
            </div>
          </>
        );
      }}
    </BlobProvider>
  );
}
