import PropTypes from "prop-types";
import { StyleSheet, View } from "@react-pdf/renderer";
import { adjust } from "../../../../utils";
import Leg from "./Leg";

const styles = StyleSheet.create({
  list: {
    paddingHorizontal: adjust(60),
  },
});

export default function LegsList({ legs }) {
  return (
    <View style={styles.list}>
      {legs.map((leg, index) => (
        <Leg
          key={leg.id}
          leg={leg}
          index={index + 1}
        />
      ))}
    </View>
  );
}

LegsList.propTypes = {
  legs: PropTypes.array,
};
