import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { adjust } from "../../../utils";

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    marginTop: adjust(100),
  },
  signatureCol: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
  },
  terms: {
    marginHorizontal: adjust(60),
    borderWidth: adjust(4),
    borderColor: "#BA9753",
    borderRadius: adjust(4),
    padding: adjust(50),
    color: "#242F42",
    marginTop: adjust(60),
  },
  title: {
    fontSize: adjust(24),
    fontWeight: 500,
  },
  label: {
    fontWeight: 500,
    fontSize: adjust(24),
    textDecoration: "none",
    color: "#242F42",
    display: "flex",
  },
  sections: {
    flexDirection: "row",
  },
  signatureUnderline: {
    borderBottom: adjust(1),
    borderColor: "#242F42",
    display: "flex",
    flex: 1,
    marginLeft: adjust(10),
  },
});

export const SignatureSection = () => {
  return (
    <View
      style={styles.terms}
      wrap={false}
    >
      <Text style={{ ...styles.title }}>
        I am happy with this quote and hereby instruct Get Heli to proceed with the booking. I confirm I have read and
        approve Get Heli’s Terms and Conditions included in this document.
      </Text>
      <View style={{ ...styles.container }}>
        <View style={styles.signatureCol}>
          <Text style={{ ...styles.label }}>Signature: </Text>
          <View style={{ ...styles.signatureUnderline }} />
        </View>
        <View style={{ ...styles.signatureCol, marginLeft: adjust(192) }}>
          <Text style={{ ...styles.label }}>Date: </Text>
          <View style={{ ...styles.signatureUnderline }} />
        </View>
      </View>
    </View>
  );
};
