import PropTypes from "prop-types";
import React from "react";
import { Document, Font, Image, Page, Text, View } from "@react-pdf/renderer";
import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import LegsList from "./components/Leg";
import HelicoptersList from "./components/Helicopter";
import Footer from "./components/Footer";
import Comments from "./components/Comments";
import { adjust } from "../../utils";
import ServicesTerms from "./components/ServicesTerms";
import { SignatureSection } from "./components/SignatureSection";

import RubikRegular from "../../assets/fonts/Rubik-Regular.ttf";
import RubikMedium from "../../assets/fonts/Rubik-Medium.ttf";

import Term1 from "../../assets/terms/GetHeliTerms1.jpg";
import Term2 from "../../assets/terms/GetHeliTerms2.jpg";
import Term3 from "../../assets/terms/GetHeliTerms3.jpg";
import Term4 from "../../assets/terms/GetHeliTerms4.jpg";
import Term5 from "../../assets/terms/GetHeliTerms5.jpg";
import Term6 from "../../assets/terms/GetHeliTerms6.jpg";
import Term7 from "../../assets/terms/GetHeliTerms7.jpg";
import Term8 from "../../assets/terms/GetHeliTerms8.jpg";

Font.register({
  family: "Rubik",
  format: "truetype",
  fonts: [{ src: RubikRegular }, { src: RubikMedium, fontWeight: 500 }],
});

export function QuoteDetails({ pdfData }) {
  const origin = window.location.origin;

  const priceObject = React.useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    const objectName = params.get("payment_method") || "european_quote_price";

    return pdfData.model?.[objectName] || pdfData.model?.quote_price || {};
  }, [pdfData.model]);

  const showTerms = React.useMemo(() => {
    if (!pdfData.has_helicopter_services_terms) return false;
    const isDevEnv = origin.includes("staging") || origin.includes("demo") || origin.includes("localhost");

    if (isDevEnv && pdfData.company.id === 628) {
      return false;
    }

    if (!isDevEnv && pdfData.company.id === 117) {
      return false;
    }

    return true;
  }, [pdfData]);

  const showListOfTerms = React.useMemo(() => {
    const isDevEnv = origin.includes("staging") || origin.includes("demo") || origin.includes("localhost");
    if (!pdfData) return false;

    if (isDevEnv && pdfData.company.id === 628) {
      return true;
    }

    if (!isDevEnv && pdfData.company.id === 14) {
      return true;
    }

    return false;
  }, [pdfData]);

  const includeBrokerFees = React.useMemo(() => {
    const params = new URLSearchParams(window.location.search);

    if (params.has("included_margin")) {
      return params.get("included_margin") === "true";
    }

    return false;
  }, []);

  const displayOperatorName = React.useMemo(() => {
    const params = new URLSearchParams(window.location.search);

    if (params.has("show_operator_name_in_pdf")) {
      if (params.get("show_operator_name_in_pdf") === "true") {
        return !!pdfData?.model?.operator_company?.name;
      }
    }

    return false;
  }, [pdfData?.model?.operator_company?.name]);

  const listOfTerms = [Term1, Term2, Term3, Term4, Term5, Term6, Term7, Term8];

  return (
    <Document>
      <Page
        size="A4"
        style={{
          fontFamily: "Rubik",
          paddingBottom: adjust(180),
        }}
      >
        <Header
          company={pdfData.company}
          user={pdfData.user}
        />
        <HeroSection
          main_img={pdfData.main_image}
          price={priceObject}
          currency={pdfData.model.currency.currency}
          includeBrokerFees={includeBrokerFees}
        />

        {displayOperatorName ? (
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: adjust(35),
              paddingHorizontal: adjust(60),
            }}
          >
            <Text style={{ fontSize: adjust(32) }}>Operated by </Text>
            <Text
              style={{
                fontSize: adjust(32),
                fontWeight: 500,
              }}
            >
              {pdfData.model.operator_company.name}
            </Text>
          </View>
        ) : (
          <View style={{ height: 1, width: 1, marginTop: adjust(25) }} />
        )}

        <LegsList legs={pdfData.model.pads} />
        <HelicoptersList aircrafts={pdfData.aircrafts} />

        {pdfData.comments ? <Comments comment={pdfData.comments} /> : null}
        {showTerms ? <ServicesTerms /> : null}

        <SignatureSection />

        <Footer company={pdfData.company} />
      </Page>

      {showListOfTerms ? (
        <>
          {listOfTerms?.map((term, index) => (
            <Page
              key={index}
              size="A4"
              style={{
                fontFamily: "Rubik",
                paddingBottom: adjust(180),
              }}
            >
              <Image
                src={term}
                style={{ width: "100%", height: "100%" }}
              />
            </Page>
          ))}
        </>
      ) : null}
    </Document>
  );
}

QuoteDetails.propTypes = {
  pdfData: PropTypes.object,
};
