import { shortenText } from "../../../../utils";

export const cutLegLocations = shortenText({ maxLen: 40, mode: "word" });

export const blockLineAlign = (object) => {
  return Object.values(object).filter(Boolean).length > 3 ? "flex-start" : "center";
};

export const blockVisibility = (object, options = { exclude: [] }) => {
  return Object.entries(object)
    .filter(([key]) => !options.exclude.includes(key))
    .some(([, value]) => Boolean(value));
};
