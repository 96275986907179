import PropTypes from "prop-types";
import { Image, Line, StyleSheet, Text, View } from "@react-pdf/renderer";
import { adjust } from "../../../utils";
import { imageHeaders } from "../../../constants";
import React from "react";
import Disclaimer from "./Disclaimer";

const styles = StyleSheet.create({
  section: {
    flexDirection: "row",
    alignItems: "center",
  },
  heliImage: {
    width: adjust(1640),
    height: adjust(820),
    overflow: "hidden",
    objectFit: "cover",
  },
  quotePirce: {
    width: adjust(840),
    height: adjust(820),
    backgroundColor: "#EDE5D4",
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: adjust(80),
    paddingVertical: adjust(125),
  },
  quotePirceContent: {
    borderWidth: adjust(4),
    borderColor: "#BA9753",
    paddingVertical: adjust(50),
    width: "100%",
    borderRadius: adjust(4),
  },
  quotePirceMain: {
    paddingHorizontal: adjust(65),
  },
  title: {
    fontSize: adjust(24),
    textTransform: "uppercase",
    fontWeight: 500,
    marginBottom: adjust(60),
  },
  total: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: adjust(20),
  },
  totalLabel: {
    fontSize: adjust(24),
    fontWeight: 500,
    marginRight: adjust(50),
  },
  totalValue: {
    fontSize: adjust(36),
    fontWeight: 500,
    paddingHorizontal: adjust(20),
    paddingVertical: adjust(16),
    borderWidth: adjust(4),
    borderColor: "#BA9753",
    borderRadius: adjust(3),
    lineHeight: 1,
  },
  detailsRow: {
    fontSize: adjust(24),
    flexDirection: "row",
    alignItems: "center",
  },
  detailsBoldText: {
    fontWeight: 500,
  },
  quotePircePositioning: {
    fontSize: adjust(24),
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: adjust(24),
    marginTop: adjust(30),
  },
  quotePricePositioningBar: {
    width: adjust(100),
    height: adjust(7),
    backgroundColor: "#fff",
  },
  quotePricePositioningBarLine: {
    backgroundColor: "#6D7481",
    position: "absolute",
    left: 0,
    top: 0,
    height: adjust(7),
  },
  progressBarRow: {
    paddingHorizontal: adjust(25),
  },
  progressBar: {
    width: adjust(160),
    height: adjust(7),
    backgroundColor: "#fff",
    position: "relative",
  },
  progressFilled: {
    width: adjust(72),
    height: adjust(7),
    backgroundColor: "#6D7481",
    position: "absolute",
    left: 0,
    bottom: 0,
  },
});

const refundable = {
  0: "No",
  1: "Yes",
  2: "Weather Only",
};

export default function HeroSection({ main_img, price, currency, includeBrokerFees }) {
  // const brokerVat = React.useMemo(() => {
  //   if (includeBrokerFees) {
  //     const params = new URLSearchParams(window.location.search);
  //     const brokerVat = params.get("broker_vat");
  //
  //     if (brokerVat && Boolean(Number(brokerVat))) return brokerVat;
  //     return "0.00";
  //   }
  //
  //   return null;
  // }, [includeBrokerFees]);

  const repositioningCost = Number(price.repositioning_cost);

  return (
    <View style={styles.section}>
      <Image
        src={{
          uri: main_img.file_url,
          method: "GET",
          headers: imageHeaders,
        }}
        style={styles.heliImage}
      />
      <View style={styles.quotePirce}>
        <View style={styles.quotePirceContent}>
          <View style={styles.quotePirceMain}>
            <Text style={styles.title}>Quote Price</Text>
            <View style={styles.total}>
              <Text style={styles.totalLabel}>Net Total</Text>
              <Text style={styles.totalValue}>
                {currency} {price.total_without_vat}
              </Text>
            </View>

            <View style={{ ...styles.detailsRow, marginBottom: adjust(15) }}>
              <Text style={{ marginRight: adjust(20) }}>excluding</Text>
              <Text style={styles.detailsBoldText}>
                {currency} {price.vat}{" "}
              </Text>
              <Text>of taxes (VAT)</Text>
            </View>

            {price.landing_fees !== "0.00" ? (
              <View style={{ ...styles.detailsRow, marginBottom: adjust(15) }}>
                <Text style={{ marginRight: adjust(25) }}>including</Text>
                <Text style={styles.detailsBoldText}>
                  {currency} {price.landing_fees}{" "}
                </Text>
                <Text>of Landing Fees</Text>
              </View>
            ) : null}

            {includeBrokerFees ? (
              <View style={{ ...styles.detailsRow, marginBottom: adjust(15) }}>
                <Text style={{ marginRight: adjust(25) }}>including</Text>
                <Text style={styles.detailsBoldText}>
                  {currency} {price.broker_margin}{" "}
                </Text>
                <Text>of Broker Fees</Text>
              </View>
            ) : null}

            {/*{brokerVat ? (*/}
            {/*  <View style={{ ...styles.detailsRow, marginBottom: adjust(15) }}>*/}
            {/*    <Text style={{ marginRight: adjust(25) }}>including</Text>*/}
            {/*    <Text style={styles.detailsBoldText}>*/}
            {/*      {currency} {brokerVat}{" "}*/}
            {/*    </Text>*/}
            {/*    <Text>of Broker Fees VAT</Text>*/}
            {/*  </View>*/}
            {/*) : null}*/}

            <View
              style={{
                ...styles.detailsRow,
                marginBottom: adjust(15),
                marginTop: adjust(15),
              }}
            >
              <Text style={{ minWidth: adjust(387), marginRight: adjust(24) }}>All landing fees included in quote</Text>
              <Text style={styles.detailsBoldText}>{price.are_landing_fees_included ? "Yes" : "No"}</Text>
            </View>

            {!price.are_landing_fees_included ? (
              <View style={{ ...styles.detailsRow, marginBottom: adjust(15) }}>
                <Text style={{ minWidth: adjust(387), marginRight: adjust(24) }}>
                  Estimate of additional landing fees
                </Text>
                <Text style={styles.detailsBoldText}>
                  {currency} {price.additional_landing_fees}
                </Text>
              </View>
            ) : null}

            <View style={{ ...styles.detailsRow, marginBottom: adjust(15) }}>
              <Text style={{ minWidth: adjust(387), marginRight: adjust(24) }}>Landing fees refundable</Text>
              <Text style={styles.detailsBoldText}>{refundable[price.are_additional_landing_fees_refundable]}</Text>
            </View>
          </View>
          {repositioningCost ? (
            <View
              style={{
                ...styles.detailsRow,
                paddingHorizontal: adjust(25),
                marginBottom: adjust(15),
                marginTop: adjust(15),
              }}
            >
              <Text
                style={{
                  minWidth: adjust(387),
                  marginRight: adjust(24),
                  fontSize: adjust(20),
                }}
              >
                Helicopter (re)positioning cost out of total {repositioningCost}%
              </Text>
              <View style={{ ...styles.progressBar }}>
                <View
                  style={{
                    ...styles.progressFilled,
                    width: adjust((160 * repositioningCost) / 100),
                  }}
                />
              </View>
              <Line />
            </View>
          ) : null}
        </View>

        <Disclaimer
          firstLineText="This document is a quote, not a booking"
          secondLineText="confirmation nor a passenger travel document."
        />
      </View>
    </View>
  );
}

HeroSection.propTypes = {
  main_img: PropTypes.object,
  price: PropTypes.object,
  currency: PropTypes.string,
  includeBrokerFees: PropTypes.bool,
};
