import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { QuoteDetailsAutoDownload, QuoteDetailsDebug } from "./templates/QuoteDetails";
import { NotFound } from "./components";

import "./index.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      retry: false,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            index
            element={
              <div className="flex h-full flex-col items-center justify-center bg-gray-100 px-5">
                <QuoteDetailsAutoDownload />
              </div>
            }
          />
          <Route
            path="/preview"
            index
            element={
              <div className="flex h-full flex-col items-center justify-center bg-gray-100">
                <QuoteDetailsDebug />
              </div>
            }
          />
          <Route
            path="*"
            element={<NotFound />}
          />
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
