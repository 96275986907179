import PropTypes from "prop-types";
import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import dayjs from "dayjs";
import { adjust } from "../../../../utils";
import { imageHeaders } from "../../../../constants";

const styles = StyleSheet.create({
  helicopter: {
    marginTop: adjust(30),
    flexDirection: "row",
    justifyContent: "space-between",
    color: "#353A3D",
  },
  block: {
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
  },
  subtitle: {
    fontSize: adjust(24),
    color: "#BA9753",
    marginBottom: adjust(15),
    textTransform: "uppercase",
  },
  title: {
    fontSize: adjust(36),
  },
  descriptionLine: {
    flexDirection: "row",
    marginBottom: adjust(5),
  },
  descriptionLabel: {
    fontSize: adjust(24),
    color: "#6D7481",
    minWidth: adjust(227),
    marginRight: adjust(8),
  },
  descriptionValue: {
    fontSize: adjust(24),
  },
  heliImage: {
    maxWidth: adjust(600),
    width: adjust(600),
    maxHeight: adjust(420),
    height: adjust(420),
    objectFit: "cover",
    overflow: "hidden",
  },
});

const approval = {
  0: "Approved",
  1: "Within 24 hours",
  2: "Within 48 hours",
  3: "Within a week",
};

export default function Helicopter({ aircraft, index }) {
  const firstImage = aircraft.images?.length > 2 && index === 0 ? aircraft.images[1] : aircraft.images[0];
  const secondImage = aircraft.images?.length > 2 && index === 0 ? aircraft.images[2] : aircraft.images[1];

  return (
    <View
      style={{ ...styles.helicopter, marginTop: index === 0 ? adjust(30) : adjust(30) }}
      wrap={false}
    >
      <View style={{ ...styles.block, width: "50%" }}>
        <View style={{ marginRight: adjust(50) }}>
          <Text style={styles.subtitle}>{aircraft.connected_legs || "Leg 1"}</Text>
          <Text style={{ ...styles.title, marginBottom: adjust(15) }}>{aircraft.aircraft_model}</Text>
          <View style={styles.descriptionLine}>
            <Text style={styles.descriptionLabel}>Manufacturer</Text>
            <Text style={styles.descriptionValue}>{aircraft.manufacturer}</Text>
          </View>
          <View style={styles.descriptionLine}>
            <Text style={styles.descriptionLabel}>Engine type</Text>
            <Text style={{ ...styles.descriptionValue, textTransform: "capitalize" }}>{aircraft.engine_type}</Text>
          </View>
          {aircraft.year_manufacture && !aircraft.date_last_refurbished ? (
            <View style={styles.descriptionLine}>
              <Text style={styles.descriptionLabel}>Year of Manufacture</Text>
              <Text style={{ ...styles.descriptionValue, textTransform: "capitalize" }}>
                {aircraft.year_manufacture}
              </Text>
            </View>
          ) : null}
          {aircraft.date_last_refurbished ? (
            <View style={styles.descriptionLine}>
              <Text style={styles.descriptionLabel}>Refurbishment date</Text>
              <Text style={styles.descriptionValue}>{dayjs(aircraft.date_last_refurbished).format("MMMM, YYYY")}</Text>
            </View>
          ) : null}
          <View style={styles.descriptionLine}>
            <Text style={styles.descriptionLabel}>Max Passengers</Text>
            <Text style={styles.descriptionValue}>{aircraft.max_passengers}</Text>
          </View>
          {aircraft.cruise_speed ? (
            <View style={styles.descriptionLine}>
              <Text style={styles.descriptionLabel}>Speed</Text>
              <Text style={styles.descriptionValue}>{aircraft.cruise_speed || 0} knots</Text>
            </View>
          ) : null}
          {aircraft?.range ? (
            <View style={styles.descriptionLine}>
              <Text style={styles.descriptionLabel}>Range</Text>
              <Text style={styles.descriptionValue}>{aircraft.range} NM</Text>
            </View>
          ) : null}
          <View style={{ ...styles.descriptionLine, marginBottom: 0 }}>
            <Text style={styles.descriptionLabel}>Owner approval</Text>
            <Text style={styles.descriptionValue}>{approval[aircraft.owner_approval]}</Text>
          </View>
        </View>
        {!!firstImage && (
          <Image
            src={{
              uri: firstImage.file_url,
              method: "GET",
              headers: imageHeaders,
            }}
            style={styles.heliImage}
          />
        )}
      </View>
      {!!secondImage && (
        <View style={styles.block}>
          <View style={{ marginRight: adjust(50) }}>
            <Text style={{ ...styles.subtitle, textAlign: "right" }}>{aircraft.connected_legs || "Leg 1"}</Text>
            <Text style={styles.title}>{aircraft.aircraft_model}</Text>
          </View>
          <Image
            src={{
              uri: secondImage.file_url,
              method: "GET",
              headers: imageHeaders,
            }}
            style={styles.heliImage}
          />
        </View>
      )}
    </View>
  );
}

Helicopter.propTypes = {
  aircraft: PropTypes.object,
  index: PropTypes.number,
};
